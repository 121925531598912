<template>
    <div class="safesetting">
        <Top name="安全设置" back email></Top>
        <van-loading v-if="isLoading" color="#1989fa" />
        <div v-else class="setting_w">
            <!-- <van-tabs v-model="active" @click="tabsClick">
                <van-tab title="实名绑定"></van-tab>
                <van-tab title="支付密码"></van-tab>
            </van-tabs> -->
            <van-row type="flex" justify="center">
                <!-- <div class="setting_title">实名绑定</div> -->
                <van-col span="24" class="idbind">
                    <h2>实名绑定</h2>
                </van-col>
                <van-col span="22" v-if="active == 0 && !bindShow" class="idbind">
                    <van-form validate-first @failed="onFailed" @submit="onSubmit">
                        <van-field
                            v-model="form.username"
                            name="validator"
                            placeholder="请输入真实姓名"
                            :rules="[{ validator: validatorName}]"
                        />
                        <van-field
                            v-model="form.userId"
                            name="validator"
                            placeholder="请输入姓名对应身份证号"
                            :rules="[{ validator: validatorId}]"
                        />
                        <!-- <div class="submit_btn">
                            <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" native-type="submit">确认绑定</van-button>
                        </div> -->
                    </van-form>
                </van-col>
                <van-col span="21" v-else-if="active == 0 && bindShow">
                    <div class="bind_w">
                        <p>姓名：{{bindObj.real_name}}</p>
                        <p>身份证号：{{bindObj.idcard}}</p>
                        <span>提示：实名绑定不支持线上修改，如需改动请联系客服</span>
                    </div>
                </van-col>
                <van-col span="24" class="idbind">
                    <!-- <div class="setting_title">支付密码</div> -->
                    <h2>支付密码</h2>
                </van-col>
                <!-- <div class="setting_title">支付密码</div> -->
                <van-col span="22" v-if="active == 0 && !passwordShow">
                    
                        <div class="password_w">
                            <span>密码设置</span>
                            <van-password-input
                                :value="form.oldPassword"
                                gutter="5"
                                :focused="showKeyboard"
                                @focus="showKeyboard = true"
                            />
                            <van-number-keyboard
                                v-model="form.oldPassword"
                                :maxlength="6"
                                :show="showKeyboard"
                                @blur="showKeyboard = false"
                            />
                        </div>
                        <div class="password_w">
                            <span>再次确认</span>
                            <van-password-input
                                :value="form.newPassword"
                                gutter="5"
                                :focused="showKeyboard1"
                                @focus="showKeyboard1 = true"
                            />
                            <van-number-keyboard
                                v-model="form.newPassword"
                                :maxlength="6"
                                :show="showKeyboard1"
                                @blur="showKeyboard1 = false, confirmPassword()"
                            />
                        </div>
                        <p class="error" v-show="!isTrue">两次密码不一致</p>
                </van-col>
                <van-col span="21" v-else>
                    <div class="pass_w">
                        <p>支付密码已设置</p>
                        <router-link :to="{ name: 'Passwordset', params: { active: 1}}">忘记密码</router-link>
                    </div>
                </van-col>
                <van-form  @submit="confirmSubmit">
                    <div v-if="!passwordShow || !bindShow" class="submit_btn" style="width: 170px;">
                        <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" native-type="submit">确认设置</van-button>
                    </div>
                </van-form>
            </van-row>
        </div>
    </div>
</template>
<script>
import Top from '@/components/top.vue'

export default {
    name: 'SafeSetting',
    components: {
        Top,
    },
    data() {
        return {
            active: 0,
            isLoading:true,
            form: {
                username: '',
                userId: '',
                oldPassword: '',
                newPassword: ''
            },
            showKeyboard: false,
            showKeyboard1: false,
            isTrue: true,
            bindObj: {
                real_name: '',
                idcard: ''
            },
            bindShow: false,
            passwordShow: false,
            
        }
    },
    created(){
        // let arr = {
        //     "code": 200,
        //     "msg":"设置成功",
        //     "data": {
        //         'realName':{'real_name':"刘*",'idcard':'130131************'},
        //         'password':true
        //     }
        // }
        //  this.passwordShow = true;
        //                 this.bindObj = arr.data.realName;
        //                 this.bindShow = true;
        // return
        //'判断支付密码是否设置接口'
        this.$api.account.isPaymentCode({type:2}).then((res)=>{
            // console.log()
            if(res.data.length!=0){
                this.passwordShow = true;
            }
        })
        //'判断是否实名绑定接口'
        this.$api.account.isPaymentCode({type:1}).then((res)=>{
            
            this.isLoading = false
            // console.log(res.data.length==0)
            if(res.data.length!=0){
                this.bindObj = res.data;
                this.bindShow = true;
            }
        }).catch((error)=>{
            this.isLoading = false
        })
    },
    methods: {
        //tab切换
        tabsClick(val) {
            //console.log(val);
            // switch(val) {
            //     case 0:
            //         this.bindShow = false;
            //         break;
            //     default: 
            //         this.passwordShow = false;
            // }
        },
        //验证姓名
        validatorName(val) {
            return val ? true : false;
        },
        //验证身份证号
        validatorId(val) {
            return val ? true : false;
        },
        onFailed(errorInfo) {
            console.log('failed', errorInfo);
        },
        //确认密码是否一致
        confirmPassword() {
            if (this.form.newPassword != this.form.oldPassword) {
                this.isTrue = false;
            }else{
                this.isTrue = true;
            }
        },
        //提交
        onSubmit() {
            // console.log(123456789)
            // type	是	string	类型：1实名绑定 2支付类型
            // real_name	是	sting	真实姓名
            // idcard	是	sting	身份证号
            // let data = {
            //     type:1,
            //     real_name:this.form.username,
            //     idcard:this.form.userId
            // }
            // this.$api.account.securitySettings(data).then((res)=>{
            //     // res.data.idcard
            //     // res.data.real_name
            //     if(res.code==200){
            //         this.bindObj = res.data;
            //         this.bindShow = true;
            //     }
                
            // })
        },
        //确认
        confirmSubmit() {
            //console.log(this.form);
            // real_name	是	sting	真实姓名
            // idcard	是	sting	身份证号
            // password	是	string	密码
            // again_password	是	string	二次密码
            // code	否	string	忘记密码时验证码
            // this.form.username
            // this.form.userId
            let rn,id,pwd,apwd = ''
            if(!this.passwordShow){
                pwd = this.form.oldPassword
                apwd = this.form.newPassword 
            }
            if(!this.bindShow){
                rn = this.form.username
                id = this.form.userId
            }
            let data = {
                real_name:rn,
                idcard:id,
                password:pwd,
                again_password:apwd
            }
            this.$api.account.SecuritySetNew(data).then((res)=>{
                    // console.log(res)
                    if(res.code==200){
                        if(res.data.password ==true){
                            this.passwordShow = true;
                        }
                        if(res.data.realName){
                            this.bindObj = res.data.realName;
                            this.bindShow = true;
                        }
                    }
                })
            // if (this.isTrue && this.form.newPassword && this.form.oldPassword && this.form.username && this.form.userId) {
            //     let data = {
            //         real_name:this.form.username,
            //         idcard:this.form.userId,
            //         password:this.form.oldPassword,
            //         again_password:this.form.newPassword
            //     }
            
            //     this.$api.account.SecuritySetNew(data).then((res)=>{
            //         // console.log(res)
            //         if(res.code==200){
            //             this.passwordShow = true;
            //             this.bindObj = res.data.realName;
            //             this.bindShow = true;
            //         }
            //     })
            //     // type	是	string	类型：1实名绑定 2支付类型
            //     // password	是	sting	密码设置
            //     // again_password	是	sting	再次确认
            //     // code	否	sting	验证码，忘记密码时传
            //     // let data = {
            //     //     type:2,
            //     //     password:this.form.oldPassword,
            //     //     again_password:this.form.newPassword
            //     // }
            //     // this.$api.account.securitySettings(data).then((res)=>{
            //     //     // console.log(res)
            //     //     if(res.code==200){
            //     //         this.passwordShow = true;
            //     //     }
            //     // })
            // }
        },
    }
}
</script>
<style scoped>
h2{
    line-height: 1.8125rem;
    color: #FF4E00;
    position: relative;
    padding: 0 .84375rem 0 1.40625rem;
    border-bottom: .0208rem solid #DEDEDE;
    text-align: left;
}
h2::before{
    content: '';
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-left-color: #EF4034;
    position: absolute;
    left: .84375rem;
    top: 50%;
    transform: translate(0, -50%);
}
.setting_w .van-row{
    padding: .9375rem 0;
}
.setting_w .idbind .van-cell{
    background-color: #efefef;
    margin-bottom: .53125rem;
    border-radius: 4px;
}
.submit_btn{
    padding: 0 1rem;
    /* margin-top: 18rem; */
}
.submit_btn button{
    font-size: 1.25rem;
}
.setting_title{
    color: #FF4E00;
    font-size: .9375rem;
    text-align: left;
    line-height: .9375rem;
    position: relative;
    margin: 0.4rem 0;
    text-indent: .4167rem;

}
.setting_title::after{
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #FF4E00;

    
}
.password_w{
    padding: .5625rem 0;
    margin-bottom: .5625rem;
    border-bottom: 1px solid #ddd;
}
.password_w>span{
    display: inline-block;
    vertical-align: middle;
    color: #313131;
    font-weight: 700;
    font-size: .9375rem;
}
.password_w .van-password-input{
    width: 70%;
    display: inline-block;
    vertical-align: middle;
}
.password_w .van-password-input__security{
    height: 2.0625rem;
}
.password_w .van-password-input__security li{
    background-color: #d6d6d6;
    border-radius: 3px;
}
.error{
    font-size: .5rem;
    color: #f00;
}
.bind_w{
    text-align: left;
    font-size: .8125rem;
    line-height: 2em;
    color: #313131;
    margin: 4% 0 0;
}
.bind_w p,
.pass_w p{
    margin-bottom: 4%;
}
.bind_w span{
    color: #f00;
}
.pass_w{
    font-size: .8125rem;
    line-height: 2em;
    color: #313131;
    margin: 4% 0 0;
}
.pass_w a{
    font-size: .8125rem;
    line-height: 1.8em;
    color: #227eeb;
    text-decoration: underline;
}
</style>
<style>
.setting_w .van-tabs__wrap{
    border-bottom: 1px solid #ddd;
}
.setting_w .van-tabs__wrap .van-tabs__line{
    background-color: #FF4E00;
}
.setting_w .van-tabs__wrap .van-tab--active{
    font-weight: 700;
    color: #FF4E00;
}
.setting_w .idbind input{
    text-align: center;
    font-size: .8125rem;
}
.setting_w .idbind .van-field__error-message{
    text-align: center;
}
</style>